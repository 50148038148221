export const CHECKLIST = 1;
export const INCIDENTES = 2;

const _nombres = Object.freeze({
  1: "Checklist",
  2: "Incidentes",
});

export const combo = Object.freeze([
  {
    text: "-",
    value: "",
  },
  {
    text: "Checklist",
    value: 1,
  },
  {
    text: "Incidentes",
    value: 2,
  },
]);

export function nombre(origenDatosId) {
  return _nombres[origenDatosId];
}
