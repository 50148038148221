<template>
  <div>
    <tabla-mobile :origenesDatos="origenesDatos" @origenDatosEliminado="onOrigenDatosEliminado" />
    <tabla-desktop :origenesDatos="origenesDatos" @origenDatosEliminado="onOrigenDatosEliminado" />
  </div>
</template>
<script>
import TablaMobile from "./TablaMobile.vue";
import TablaDesktop from "./TablaDesktop.vue";
export default {
  components: {
    TablaMobile,
    TablaDesktop,
  },
  props: {
    origenesDatos: Array,
  },
  methods: {
    onOrigenDatosEliminado(id) {
      this.$emit("origenDatosEliminado", id);
    },
  },
};
</script>
