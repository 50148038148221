<template>
  <acciones>
    <accion-primaria
      :label="$t('Editar')"
      icon="edit"
      :to="`/administracion/origenes-de-datos/editar/${origenDatos.id}`"
    />

    <acciones-secundarias>
      <accion-eliminar
        @eliminar="eliminarOrigenDatos(origenDatos)"
        :confirmacion="`¿Desea eliminar el origen de datos ${origenDatos.nombre}?`"
      />
    </acciones-secundarias>
  </acciones>
</template>
<script>
import * as apiOrigenesDatos from "@/services/origenesDatos.js";
export default {
  props: {
    origenDatos: Object,
  },
  methods: {
    async eliminarOrigenDatos(origenDatos) {
      await apiOrigenesDatos.eliminar(origenDatos.id);
      this.$root.$emit("userMessage", this.$t("Origen de datos eliminado"));
      this.$emit("origenDatosEliminado", origenDatos.id);
    },
  },
};
</script>
