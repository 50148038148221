<template>
  <div class="listado tabla-mobile">
    <filtros-listado-mobile>
      <filtro-activo field="activo" />
    </filtros-listado-mobile>

    <fila-compacta v-for="(origenDatos, idx) in origenesDatos" v-bind:key="idx" :titulo="origenDatos.nombre">
      <div>
        {{ $t("Tipo") }}: <span>{{ getTipoStr(origenDatos.tipoId) }}</span
        ><br />
        {{ $t("Estado") }}:
        <span v-if="origenDatos.activo == true">Activo</span>
        <span v-else>Inactivo</span>
      </div>

      <botonera-acciones-tabla
        slot="toolbar"
        :origenDatos="origenDatos"
        @origenDatosEliminado="onOrigenDatosEliminado"
      />
    </fila-compacta>

    <h3 class="text-center" v-if="origenesDatos.length == 0">
      {{ $t("No hay orígenes de datos") }}
    </h3>
  </div>
</template>

<script>
import BotoneraAccionesTabla from "./BotoneraAccionesTabla.vue";
import * as TipoOrigenDatos from "@/enum/TipoOrigenDatos";

export default {
  props: {
    origenesDatos: { type: Array, required: true },
  },
  data() {
    return {
      activo: 1,
    };
  },
  components: { BotoneraAccionesTabla },
  methods: {
    getTipoStr(tipoId) {
      return TipoOrigenDatos.nombre(tipoId);
    },
    onOrigenDatosEliminado(id) {
      this.$emit("origenDatosEliminado", id);
    },
  },
};
</script>
