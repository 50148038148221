<template>
  <page-listado title="Orígenes de datos" con-busqueda>
    <filtro-activo field="activo" class="float-left mr-3 d-md-block d-none" />

    <template slot="toolbar">
      <b-button variant="primary" size="sm" to="/administracion/origenes-de-datos/crear">
        <v-icon name="plus" width="16"></v-icon>
        {{ $t("Crear") }}
      </b-button>
    </template>

    <tabla :origenesDatos="resultado.origenesDatos" @origenDatosEliminado="origenDatosEliminado"></tabla>

    <paginacion
      v-if="resultado.numeroPaginas > 1"
      :page-count="resultado.numeroPaginas"
      :page="resultado.pagina"
      :pageRange="3"
    ></paginacion>
  </page-listado>
</template>

<script>
import * as apiOrigenesDatos from "@/services/origenesDatos.js";
import Tabla from "./Tabla.vue";
export default {
  components: { Tabla },
  data() {
    return {
      resultado: {
        origenesDatos: [],
        numeroPaginas: 0,
        pagina: 1,
      },
    };
  },
  methods: {
    async listar() {
      this.resultado = await apiOrigenesDatos.listar(this.$route.query);
    },
    origenDatosEliminado(id) {
      this.listar();
    },
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.listar();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
