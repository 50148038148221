<template>
  <table class="listado table table-hover m-0 tabla-desktop">
    <thead class="bg-primary text-white">
      <th>{{ $t("Nombre") }}</th>
      <th>{{ $t("Tipo") }}</th>
      <th>{{ $t("Estado") }}</th>
      <th class="th-acciones">{{ $t("Acciones") }}</th>
    </thead>
    <tbody>
      <tr v-for="origenDatos in origenesDatos" v-bind:key="origenDatos.id">
        <td>{{ origenDatos.nombre }}</td>
        <td>{{ getTipoStr(origenDatos.tipoOrigen) }}</td>
        <td v-if="origenDatos.activo == true">Activo</td>
        <td v-else>Inactivo</td>
        <td nowrap="nowrap" class="td-acciones">
          <botonera-acciones-tabla :origenDatos="origenDatos" @origenDatosEliminado="onOrigenDatosEliminado" />
        </td>
      </tr>
      <sin-registros :count="origenesDatos.length" :colspan="6"></sin-registros>
    </tbody>
  </table>
</template>
<script>
import BotoneraAccionesTabla from "./BotoneraAccionesTabla.vue";
import * as TipoOrigenDatos from "@/enum/TipoOrigenDatos";
export default {
  props: {
    origenesDatos: Array,
  },
  methods: {
    getTipoStr(tipoId) {
      return TipoOrigenDatos.nombre(tipoId);
    },
    onOrigenDatosEliminado(id) {
      this.$emit("origenDatosEliminado", id);
    },
  },
  components: {
    BotoneraAccionesTabla,
  },
};
</script>
